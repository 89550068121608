:root {
  --black-color:#00000C;
  --white-color: #F2F2F2;
  --navy-color: #2f2e41;
  --darksea-color: #1b242f;
  --oxford-blue:#001A37;
  --gray-color:#50514F;
  --nickel-color:#6E6F6E;
  --quick-silver-color:#A2A29D;
  --battleship-color: #898B88;
  --hunter-green-color: #386641;
  --may-green-color: #6A994E;
  --bush-green-color: #9DBF9E;
  --yellow-green-color: #CDD993;
  --pale-spring-color:#E0E1B1;
  --eggshell-green: #F2E8CF;
  --beige-color: #F0D8BE;
  --camel-color: #E8C1A3;
  --bittersweet-red-color:#BC4749;
  --red-color: #F25F5C;
  --pink-color: #DC8888;
  --pastel-red-color:#A74F6A;
  --purple-color: #987284;
  --silver-pink: #D4BCC0;
  --isabeline-pink: #F6F2F0;
  --hotmess-pink-color: #E31B6D;
  --turquoise-color:#2FEFD0;
  --char45-width: 20ch;
  --char-count:50ch;
  --char4-width: 4ch;
  /*smaller variables*/
  --phonemd-bg-color:var(--bittersweet-red-color);
  --phonelg-bg-color: var(--darksea-color);
  --componentbg-color:var(--battleship-color);
  --navsm-bg-color:var(--battleship-color);


  /*normal variables*/
  --main-bg-color: var( --oxford-blue);
  --nav-bar-color: var(--darksea-color);
  --hover-nav-color: var(--hunter-green-color);
  --name-landing-color: var(--purple-color);
  --smallscreen-name-color:var(--pink-color);
  --connect-link-color: var(--white-color);
  --title-header-color: var(--isabeline-pink);
  --description-words-color:var(--white-color);
  --repo-cards-bk:var(--battleship-color);
  --techstack-word-color:var(--white-color);

  --project-title-color: var(--silver-pink); 
  

  --strong-color: var(--hotmess-pink-color);

  --button-bk-color:var(--hotmess-pink-color);
  --button-color:var(--white-color);
  --card-color:var(--quick-silver-color);

  /*styles var*/
  --character-length: var(--char45-width);
  --github-card: var(--char4-width);
  /*margins/flex*/
  --spacing: 1rem;



  /*svg color var*/
  --body-color:var(--camel-color);
  --shirt-color: var(--pastel-red-color);
  --pants-color: var(--purple-color);
  --hair-color:var(--black-color);  
  --paper-color:var(--white-color);
  --pad-color:var(--navy-color);

}
