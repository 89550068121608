.stack{
  display: flex;
  font-size:2em;
  margin-bottom:13px;
  margin-top: 20px; 
}
.stack-item{
  margin: 1rem 1.25rem;
  display: flex;
  justify-content: center;
}
.javascript{
  color: yellow;
}
.react{
  color:#5ED2F3;
}
.ruby{
  flex-direction: row;
  color:red;
}
strong {
  color: var(--strong-color);
}
.card-wrap{
  display:block;
  margin: 2rem;
}

.tittle{
  font-weight: bold;
  font-size:2.8em;
  color:var(--project-title-color);
  margin-top: 2rem;
 
}
.card-meta{
  display: flex;
  flex-direction: row;
}

  
@media only screen and (min-width: 730px) {
    .card-meta-rev{
      display: flex;
      flex-direction: row-reverse;
  }
}
@media only screen and (max-width: 729px) {
    .card-meta-rev{
      flex-direction: row;
  }
}
.pic-cour{
  width: auto;
  overflow:scroll;      
  white-space: nowrap;
  box-sizing:border-box;

}

/* .image-wrap{
  width: 80%;
} */
img{
  height:100vh;
  box-sizing: inherit;
  max-width: 90%;
  padding: 2rem;
  width: 450px;
  height: auto;

}
.paragraph-descrition{
  box-sizing: inherit;
  max-width: 100%;
  width: 100%;
  height: auto;
  font-size: 1.3em;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: .01071em;
  width: auto;
  /* max-width: var(--char-count); */
}
.work-description{
  display: flex;
  flex-direction: column;
  padding: 28px;
}
.tech-stack{
  font-weight: bold;
  font-size:2.8em;
  color: var(--techstack-word-color);
  margin-top: 2rem;
  margin-left:1.25rem;
}
.frontend-stack-pastwork{
  font-size:1.5em;
  line-height: 1.167;
  margin-left: .5rem;
}
.link{
  font-size:2rem;
  line-height: 1.167;
  box-sizing: inherit;
  max-width: 100%;
  width: 100%;
  height: auto;
  color:var(--description-words-color) !important;
 
}
.static{
  font-size:1.5em;
  line-height: 1.167;
  box-sizing: inherit;
  max-width: 100%;
  width: 100%;
  height: auto;
  color:var(--description-words-color);
}
a{
  color: white !important;
  text-decoration: none;
}

@media screen and (max-width: 700px){
  .card-meta{
    display: flex;
    flex-direction: column;
  }
  .paragraph-descrition{
    font-size:1.5em;
  }
}
@media screen and (max-width: 500px) {
  .stack{
    display: flex;
    flex-direction: column;
    margin-left: 1rem; 
  }
  .stack-item{
    margin: 1rem 1.25rem;
    display: flex;
    justify-content: flex-start;
    font-size:1.8rem;
  }
}
/* @media screen and (max-width: 974px){
      .projects{
        display:block;
      }
} */
