.wrap{
  /* display: flex; */

}
.box-repo{
 display:flex;
 flex-direction: row ;
}

.box-github-cards{
 display: flex;
 overflow: scroll;
}
.repo-card{
  background-color: #fff;
  margin:1rem;
}
.card{
  background: red;
  width: auto;
  height: auto;
  border: 30px solid white;
  text-align: center;
  line-height: 240px;
  box-sizing: border-box;
}

.button-wrap{
}

.description{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  width: auto;
  max-width: 30ch;
}

.github-paragraph{
    box-sizing: inherit;
    font-size: 1.3em;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: .01071em;
    width: auto;
    max-width: var(--char-count);
    margin-left: 2rem;
  
}

.cardClass{
  margin: 2rem;
}

.scrolling-wrapper{
  width: auto;
  overflow:scroll;      
  white-space: nowrap;
  box-sizing:border-box;
  
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: transparent!;
  padding: 10px;
  line-height: 5em;
}
.calendar-days{
  /* all: revert; */
  display: grid;
  grid-column: 1;
  background-color: #333;
  display: inline-block;
  color: white;
  /* text-align: center; */
  height: 250px;
  width: 200px;
  padding: 1rem;
  margin:1rem;
  text-decoration: none;
  align-items:baseline;
}
.sub-card{
 display: flex;
 flex-direction: column;
 /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
 transition: 0.3s;
 width: 50%;
 height: 90%;
}
div.scrollmenu {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: #777;
}
.primary-button{
  background-color: var(--button-bk-color);
  color: white;
  border-radius: 1rem ;
  padding: .5rem;
  /* text-align: center; */
  /* text-decoration: none; */
  /* display: inline-block; */
  /* margin: 1rem; */
  /* cursor: pointer; */
}
.sub-tittle{
  
}
@media screen and (max-width: 500px) {
  .grid-container{
    display: flex;
    flex-direction: column;
  }
  /* .link-wrap{
    margin-left: 2rem;
  }
  .social-link{
    font-size: 1.5rem;
  } */
  

}
/* @media screen and (max-width: 500px) {
  .stack{
    display: flex;
    flex-direction: column;
    margin-left: 1rem; 
  }
  .stack-item{
    margin: 1rem 1.25rem;
    display: flex;
    justify-content: flex-start;
    font-size:1.8rem;
  }
} */
/* .card-container{

  --main-bg-color: #343b3f;
  --selected-text-color: #212121;
  --selected-text-bg-color: #aadc39;
  --main-text-color: #fff;
  --calendar-text-color: #f5f5f5;
  --nav-footer-bg-color: rgba(37,37,37,0.98);
  --small-padding: 1rem;
  --med-margin: 1.2rem;
  --twitter-brand: #1da1f2;
  --dull-white: #c5c4bf;
  padding: 0;
  font-family: Manrope,sans-serif;
  color: var(--main-text-color);
  display: inline-grid;
  margin: 1rem;
  grid-template-columns: repeat(2,42%);
  align-items: stretch;
  justify-content: space-around;
} */