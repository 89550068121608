/* !about and connect same component sorry not */
.connectCard{
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
   
}
svg{
  height:100vh;
  max-width: 90%;;
}
.svg-man{
  width: 100%;
}
.content{
  display: flex;
  flex-direction: column;
  margin-right: 1.8rem;
}
.about{
  font-size:1.3em;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  width: auto;
  max-width: var(--char-count)
}
.component-header-connect{
   font-weight: bold;
  font-size:2.4em;
  color: var(--techstack-word-color);
  margin-top: 3rem !important;

}
.social-links-contact{
  display: flex;
  font-size:2em;
  margin-bottom:13px;
  margin-top: 20px; 
}
@media screen and (max-width: 500px){
.connectCard{
    padding: 2rem 1.5rem 1.5rem 2rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.paragraph-descrition{
  font-size: 1.5rem;
  margin: 1rem;
}
.about{
  padding-left: 2rem;
  font-size: 1.5rem;
}
.connectCard {
  padding: 0;
}
}