nav, .navigation-bar{
  font-size: 12pt;
  line-height: 18pt;
  background:var(--nav-bar-color);
  padding: 14px 16px;
  width: 100%;
  z-index: 9 !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  justify-content:center;
}
.icon .nav-link:hover{
  color: var(--hover-nav-color);
}
.nav-link{
  color: var(--white-color);
  cursor: pointer;
  text-transform: uppercase;
  transition: color 0.5s;
  font-size: initial;
  margin: 1rem;
  padding-top: 1rem;
}
.icon{
  display: none;
}
@media screen and (max-width: 500px) {
  .container{
    background-color: var(--phonelg-bg-color);
  }
  nav, .navigation{
    background-color: var(--navsm-bg-color);
  }
  .nav-link{
    display:none;
  }
  .icon{
    display: inline;
    color: var(--white-color);
    cursor: pointer;
    transition: color 0.5s;
    margin: 1.5rem;
    width: 5px;
    height: 5px;
    size:10px;
  }
  
}